require("./lib/pym");
// var L = require("leaflet");
// require("../assets/js/kai_chart");
var utils = require("./lib/utils");
var Chart = require('chart.js');
var ChartDataLabels = require('chartjs-plugin-datalabels');
var Retriever = require('./lib/retriever');
const $ = require("jquery");

let fname = $("main").data("json"),
	json_url = 'assets/data/county_proc/' + fname,
	interval = 300;

percChange = (cur_num, past_num) => {
	perc = Number(cur_num) / Number(past_num)
	perc = parseFloat((perc - 1) * 100)
	return perc.toFixed(2)
}

counter = (div) => {
	div.each(function () {
		$(this).prop('Counter', 0).animate({
			Counter: $(this).text()
		}, {
			duration: 1800,
			easing: 'swing',
			step: function (now) {
				$(this).text(Math.ceil(now).toLocaleString('en'));
			}
		});
	})
}

$(document).ready(function () {
	var update = new Retriever(function (data) {
		console.log(data);
        data['Name'] = 'Spokane County';
		$('#place-name').text(data['Name']);
		$('#county-name').text('County Name Goes Here');

		// if (data['Name'].includes("village")) {
		// 	introtext = data['Name'] + ' is a village in x County on Long Island.'
		// } else {
		// 	introtext = data['Name'] + ' is a Census Designated Place (CDP) in x County on Long Island. CDPs are unincorporated communities that are delineated by the Census Bureau for statistical purposes. They often correspond with our understanding of hamlets.'
		// }

		$('#introtext').text('Placeholder TEXT')
        buildHousingdist()
		buildDemo(data);
		buildEcon(data);
        buildIncomevRent(data);
        buildBurden(data);
        buildHomeValues(data);
        

	});
	// timer(interval);
	update.watch(json_url); //, interval
});

timer = (interval) => {
	// let d = new Date(),
	// 	currentTime = Date.parse(d),
	// 	timeInSeconds = interval,
	// 	countDownDate = new Date(currentTime + timeInSeconds * 1000);
	//
	// $('.update_date').text("Results last checked on " + d.toLocaleString());
	// var x = setInterval(function () {
	//
	// 	let now = new Date().getTime(),
	// 		distance = countDownDate - now,
	// 		seconds = Math.floor((distance % (1000 * 60)) / 1000);
	//
	// 	// Display the result in the element with id="demo"
	// 	$('.timer').text("Next update in: " + seconds + "s ")
	//
	// 	// If the count down is finished, write some text
	// 	if (distance < 0) {
	// 		clearInterval(x);
	// 		$('.timer').text("Updating...")
	// 		timer(interval);
	// 	}
	// }, interval);

}

buildDemo = (data) => {
	let age_dict = {
		"0-9": ["Under 5 years", "5 to 9 years"],
		"10-19": ["10 to 14 years", "15 to 17 years", "18 and 19 years"],
		"20-29": ["20 years", "21 years", "22 to 24 years", "25 to 29 years"],
		"30-39": ["30 to 34 years", "35 to 39 years"],
		"40-49": ["40 to 44 years", "45 to 49 years"],
		"50-59": ["50 to 54 years", "55 to 59 years"],
		"60-69": ["60 and 61 years", "62 to 64 years", "65 and 66 years", "67 to 69 years"],
		"70-79": ["70 to 74 years", "75 to 79 years"],
		"80+": ["80 to 84 years", "85 years and over"],
	}

	let pie_dict = {
		"Under 18": ["Under 5 years", "5 to 9 years", "10 to 14 years", "15 to 17 years"],
		"Young adult": ["18 and 19 years", "20 years", "21 years", "22 to 24 years", "25 to 29 years", "30 to 34 years", "35 to 39 years", "40 to 44 years", "45 to 49 years", "50 to 54 years", "55 to 59 years", "60 and 61 years", "62 to 64 years", ],
		"65+": ["65 and 66 years", "67 to 69 years", "70 to 74 years", "75 to 79 years", "80 to 84 years", "85 years and over"]
	}

	// let pie_dict = {
	// 	"Under 18": ["Under 5 years", "5 to 9 years", "10 to 14 years", "15 to 17 years"],
	// 	"18 - 34": ["18 and 19 years", "20 years", "21 years", "22 to 24 years", "25 to 29 years", "30 to 34 years"],
	// 	"35 - 49": ["35 to 39 years", "40 to 44 years", "45 to 49 years"],
	// 	"50 - 64": ["50 to 54 years", "55 to 59 years", "60 and 61 years", "62 to 64 years", ],
	// 	"65+": ["65 and 66 years", "67 to 69 years", "70 to 74 years", "75 to 79 years", "80 to 84 years", "85 years and over"]
	// }

	let ctx = document.getElementById('age_distro').getContext('2d');
    let pop_time = document.getElementById('pop_time').getContext('2d');
	let pie_div = document.getElementById('age_pie').getContext('2d');
	let popchange = percChange(data['population']['2019']['Total'], data['population']['2014']['Total'])
	let householdchange = percChange(data['household_total']['2019']['Total households'], data['household_total']['2014']['Total households'])

	let poptextchange, householdtextchange, popcolor, housecolor = ''

	if (popchange >= 0) {
		popchange = (popchange < 0 ? "" : "+") + popchange + '% since 2014';
		popcolor = "#7cd382";
		poptextchange = 'increased';
	} else {
		popchange = popchange + '% since 2014';
		popcolor = "#e86666";
		poptextchange = 'decreased';
	}

	if (householdchange >= 0) {
		householdchange = (householdchange < 0 ? "" : "+") + householdchange + '% since 2014';
		housecolor = "#7cd382";
		householdtextchange = 'increased';
	} else {
		householdchange = householdchange + '% since 2014';
		housecolor = "#e86666";
		householdtextchange = 'decreased';
	}

	file = 'assets/data/all_population.json'
    fetch(file).then(res=>res.json()).then(data => {
        data = data.sort((a,b)=>
            a.Change < b.Change ? 1: -1
        )
        Object.entries(data).forEach((entry, index) => {
            if (index < 3 || entry[1]['Name'] == 'Spokane County'){
                let demotext = "\
                <tr class='striped--near-white'>\
                    <td class='pv2 ph2 tc'>#"+(index+1)+"</td>\
                    <td class='pv2 ph2 tl'>"+entry[1]['Name']+"</td>\
                    <td class='pv2 ph2 tr'>"+entry[1]['Change'].toFixed(2)+"%</td>\
                </tr>\
                "
                $('#demotext').append(demotext).slideDown('slow')
            }
        })
    }).catch(err => {throw err});

	$('#popnum').text(data['population']['2019']['Total'])
	$('#popchange').css("color", popcolor)
	$('#popchange').text(popchange)

	$('#household').text(data['household_total']['2019']['Total households'])
	$('#householdchange').css("color", housecolor)
	$('#householdchange').text(householdchange)

	counter($('#popnum'));
	counter($('#household'));

	let labels = [];
	let values = [];
	let pie_labels = [];
	let pie_values = [];
	let age_distro_values = [];
	total = 0;

    Object.entries(age_dict).forEach(entry => {
        temp = 0
		for (var i = 0; i < entry[1].length; i++) {
			male = 'Male!!' + entry[1][i]
			female = 'Female!!' + entry[1][i]
			temp += data['age_group']['2019'][male]
			temp += data['age_group']['2019'][female]
		}
		labels.push(entry[0])
		values.push(temp)
    })

    Object.entries(pie_dict).forEach(entry => {
        temp = 0
		for (var i = 0; i < entry[1].length; i++) {
			male = 'Male!!' + entry[1][i]
			female = 'Female!!' + entry[1][i]
			temp += data['age_group']['2019'][male]
			temp += data['age_group']['2019'][female]
		}
		pie_labels.push(entry[0])
		pie_values.push(temp)
        total += temp
    })

	values.forEach(item => {
		temp = parseInt((item / total) * 100)
		age_distro_values.push(temp)
	});

    pop_time_labels = []
    pop_time_values = []
    Object.entries(data['population']).forEach(entry=>{
        if (entry[1].values != undefined){
            pop_time_labels.push(entry[0])
            pop_time_values.push(entry[1]['Total'])
        }
    })

    const base = pop_time_values[0]
    Object.values(pop_time_values).forEach((pop, index)=>{
        console.log(pop)
        pop_time_values[index] =  (pop/(base))*100
    })

    console.log(pop_time_values)

    var pop = new Chart(pop_time, {
        type: 'line',
        data: {
            labels: pop_time_labels,
            datasets: [{
                label: 'Spokane',
                data: pop_time_values,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1
            }]
        },
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Population growth, indexed'
                }
            }
        }
    });

	var age_distro = new Chart(ctx, {
		type: 'bar',
		data: {
			labels: labels,
			datasets: [{
				label: 'Age distribution',
				data: age_distro_values,
				backgroundColor: [
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(75, 192, 192, 0.2)'
                ],
				borderColor: [
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(75, 192, 192, 1)'
                ],
			    borderWidth: 1
          }]
		},
		options: {
			plugins: {
                tooltip: {
                    callbacks: {
                        title: function (tooltipItem) {
                            return 'Age group: ' + tooltipItem[0]['label'];
                        },
                        label: function (tooltipItem, data) {
                            return ['Percentage: ' + tooltipItem['formattedValue'] + '%', 'Total: ' + values[tooltipItem['dataIndex']].toLocaleString('en')];
                        },
                    }
                }
			},
		}
	});

	var age_pie = new Chart(pie_div, {
		type: 'doughnut',
		data: {
			datasets: [{
				data: pie_values,
				backgroundColor: [
                window.chartColors.red,
                window.chartColors.green,
                window.chartColors.blue,
				window.chartColors.orange,
				window.chartColors.purple,
                ],
                label: ''
            }],
			labels: pie_labels
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			animation: {
				animateRotate: false,
				animateScale: true
			},
			plugins: {
                tooltip: {
                    callbacks: {
                        title: function (tooltipItem) {
                            return 'Age group: ' + tooltipItem[0]['label'];
                        },
                        label: function (tooltipItem, data) {
                            return ['Percentage: ' + Math.round((tooltipItem['raw']/values.reduce((a, b) => a + b, 0))*100) + '%', 'Total: ' +tooltipItem['formattedValue']];
                        },
                    }
                }
			},
			animation: {
				onComplete: function (e) {
					// Emulate hover/clicking age 18-64
					this.options.animation.onComplete = null;
					var meta = age_pie.getDatasetMeta(0),
						rect = age_pie.canvas.getBoundingClientRect(),
						point = meta.data[1].getCenterPoint(),
						evt = new MouseEvent('click', {
							clientX: rect.left + point.x,
							clientY: rect.top + point.y
						}),
						node = pie_div.canvas;
					node.dispatchEvent(evt);
				}
			},
		}
	});
    // $('#demotext').parent().parent().show(500)
}

buildEcon = (data) => {
	let percapitachange = percChange(data['median_income_rent']['2019']['Median INC adjusted to year'], data['median_income_rent']['2014']['Median INC adjusted to year'])
	let medianincchange = percChange(data['median_income_rent']['2019']['Per capita income adjusted to year'], data['median_income_rent']['2014']['Per capita income adjusted to year'])

	let poptextchange, householdtextchange, popcolor, housecolor = ''

	if (percapitachange >= 0) {
		percapitachange = (percapitachange < 0 ? "" : "+") + percapitachange + '% since 2014';
		percapitacolor = "#7cd382";
		percapitatextchange = 'increased';
	} else {
		percapitachange = percapitachange + '% since 2014';
		percapitacolor = "#e86666";
		percapitatextchange = 'decreased';
	}

	if (medianincchange >= 0) {
		medianincchange = (medianincchange < 0 ? "" : "+") + medianincchange + '% since 2014';
		medianinccolor = "#7cd382";
		medianinctextchange = 'increased';
	} else {
		medianincchange = medianincchange + '% since 2014';
		medianinccolor = "#e86666";
		medianinctextchange = 'decreased';
	}

	let econtext = "\
      <p class='mv3'>Placeholder - I'm thinking we can do an automated comparison with WA state income and perhaps national median income? If you have any ideas, feel free to <a href='mailto:kai@ink-cafe.com'>email me</a>.</p>\
    "

	$('#percapita').text(data['median_income_rent']['2019']['Per capita income adjusted to year'])
	$('#percapitachange').css("color", percapitacolor)
	$('#percapitachange').text(percapitachange)

	$('#medianinc').text(data['median_income_rent']['2019']['Median INC adjusted to year'])
	$('#medianincchange').css("color", medianinccolor)
	$('#medianincchange').text(medianincchange)

	counter($('#percapita'));
	counter($('#medianinc'));

	$('#econtext').html(econtext);
}

buildIncomevRent = (data) => {
    values = []

    Object.entries(data["median_income_rent"]).forEach((entry) => {
        let year = entry[0]
        let dict = entry[1]
        if (dict.values != undefined){
            let newdict = {}
            newdict["Year"] = year;
            newdict["Median INC adjusted to year"]= dict["Median INC adjusted to year"];
            newdict["Median gross rent"]= dict["Median gross rent"];

            values.push(newdict)
        }
    })

    utils.createChart('pareto', values, {
        'step': 2,
        'category': 'Year',
        'field': [['Median household income','Median INC adjusted to year'], ['Median rent', 'Median gross rent']],
        'div': 'income_rent',
        'prefix': '$',
        'format': '#,###.##',
        'title': 'Median household income and rent'
    })
    
}

buildBurden = (data) => {
    values = []
    
    Object.entries(data["burden"]).forEach(entry => {
        // console.log(entry)
        let year = entry[0]
        let dict = entry[1]

        if (dict.values != undefined){
            // time to figure out renters
            let renters = dict['Total renters'] - dict['Not computed']
            let renters35 = dict['Rent as % of HHI:!!35.0 to 39.9 percent'] + dict['Rent as % of HHI:!!40.0 to 49.9 percent']
            let renters50 = dict['Rent as % of HHI:!!50.0 percent or more']

            let homeowners = dict['Total owners'] - (dict['Housing units with a mortgage:!!Not computed']+dict['Housing units without a mortgage:!!Not computed'])
            let homeowners35 = dict['Housing units with a mortgage:!!35.0 to 39.9 percent']+dict['Housing units with a mortgage:!!40.0 to 49.9 percent']+dict['Housing units without a mortgage:!!35.0 to 39.9 percent']+dict['Housing units without a mortgage:!!40.0 to 49.9 percent']
            let homeowners50 = dict['Housing units with a mortgage:!!50.0 percent or more']+dict['Housing units without a mortgage:!!50.0 percent or more']       

            let newdict = {}
            newdict["year"] = year;
            newdict["35-49%"] = ((renters35+homeowners35)/(renters+homeowners))*100;
            newdict["50%+"] = ((homeowners50+renters50)/(renters+homeowners))*100;

            values.push(newdict)
        }
    })

    utils.createChart('stacked', values, {
        'step': 2,
        'category': 'year',
        'field': ['35-49%', '50%+'],
        'div': 'household_cost',
        'min': 0,
        'max': 40,
        'suffix': '%',
        'format': '#.',
        'title': 'Households with housing cost burdens',
    })
}

buildHomeValues = (data) => {
    // "year": "2019",
    // "Median home value": 489518.07
    values = []
    
    Object.entries(data["home_value"]).forEach(entry => {
        let year = entry[0]
        let dict = entry[1]
        if (dict.values != undefined){
            values.push({
                "year": year,
                "Median home value": dict["Median value (dollars)"]
            })
        }
    })

    utils.createChart('line', values, {
        'step': 2,
        'category': 'year',
        'field': ['Median home value'],
        'div': 'median_home_value',
        'prefix': '$',
        'format': '#,###.##',
        'title': 'Median home value (unadjusted)'
    })
}

buildHousingdist = () => {
    
	
	// Themes begin
	am4core.useTheme(am4themes_animated);
	am4core.useTheme(utils.am4themes_reverseMaterial);
	// Themes end
	$('#valuehdist').height(500);
	var chart = am4core.create("valuehdist", am4charts.XYChart);
	
	chart.data = [{
		"year": "2008",
		"Less than $100k": 15557,
		"$100k - 200k": 77978,
		"$201k - 300k": 106819,
		"301k - 400k": 52524,
		"401k - 500k": 21539,
		"501k - 600k": 14074,
		"601k - 700k": 1806,
		"701k - 800k": 5288,
		"801k - 900k": 2902,
		"More than 901k": 6448,
	},{
		"year": "2009",
		"Less than $100k": 21802,
		"$100k - 200k": 90257,
		"$201k - 300k": 99059,
		"301k - 400k": 38928,
		"401k - 500k": 29401,
		"501k - 600k": 9257,
		"601k - 700k": 3640,
		"701k - 800k": 2909,
		"801k - 900k": 4120,
		"More than 901k": 5713,
	},{
		"year": "2010",
		"Less than $100k": 23609,
		"$100k - 200k": 96691,
		"$201k - 300k": 94154,
		"301k - 400k": 36791,
		"401k - 500k": 24772,
		"501k - 600k": 11036,
		"601k - 700k": 3655,
		"701k - 800k": 3789,
		"801k - 900k": 1611,
		"More than 901k": 3167,
	},{
		"year": "2011",
		"Less than $100k": 25176,
		"$100k - 200k": 106766,
		"$201k - 300k": 101005,
		"301k - 400k": 39145,
		"401k - 500k": 16462,
		"501k - 600k": 12889,
		"601k - 700k": 3191,
		"701k - 800k": 1779,
		"801k - 900k": 2990,
		"More than 901k": 1575,
	},{
		"year": "2012",
		"Less than $100k": 26971,
		"$100k - 200k": 134813,
		"$201k - 300k": 82978,
		"301k - 400k": 31397,
		"401k - 500k": 14465,
		"501k - 600k": 3731,
		"601k - 700k": 2588,
		"701k - 800k": 99,
		"801k - 900k": 1144,
		"More than 901k": 3740,
	},{
		"year": "2013",
		"Less than $100k": 33212,
		"$100k - 200k": 116360,
		"$201k - 300k": 74407,
		"301k - 400k": 32213,
		"401k - 500k": 20092,
		"501k - 600k": 6092,
		"601k - 700k": 1635,
		"701k - 800k": 2045,
		"801k - 900k": 1634,
		"More than 901k": 1650,
	},{
		"year": "2014",
		"Less than $100k": 28165,
		"$100k - 200k": 117735,
		"$201k - 300k": 78841,
		"301k - 400k": 36153,
		"401k - 500k": 16337,
		"501k - 600k": 8788,
		"601k - 700k": 4332,
		"701k - 800k": 1220,
		"801k - 900k": 376,
		"More than 901k": 5117,
	},{
		"year": "2015",
		"Less than $100k": 21802,
		"$100k - 200k": 112718,
		"$201k - 300k": 82069,
		"301k - 400k": 33686,
		"401k - 500k": 19716,
		"501k - 600k": 7211,
		"601k - 700k": 4706,
		"701k - 800k": 2600,
		"801k - 900k": 1872,
		"More than 901k": 4103,
	},{
		"year": "2016",
		"Less than $100k": 22679,
		"$100k - 200k": 106973,
		"$201k - 300k": 90690,
		"301k - 400k": 33654,
		"401k - 500k": 21911,
		"501k - 600k": 9999,
		"601k - 700k": 3821,
		"701k - 800k": 399,
		"801k - 900k": 4257,
		"More than 901k": 3552,
	},{
		"year": "2017",
		"Less than $100k": 21787,
		"$100k - 200k": 94041,
		"$201k - 300k": 113724,
		"301k - 400k": 49522,
		"401k - 500k": 22457,
		"501k - 600k": 7950,
		"601k - 700k": 6185,
		"701k - 800k": 3495,
		"801k - 900k": 1849,
		"More than 901k": 4019,
	},{
		"year": "2018",
		"Less than $100k": 18519,
		"$100k - 200k": 78161,
		"$201k - 300k": 123029,
		"301k - 400k": 65260,
		"401k - 500k": 33831,
		"501k - 600k": 9045,
		"601k - 700k": 8293,
		"701k - 800k": 3271,
		"801k - 900k": 1185,
		"More than 901k": 3135,
	},{
		"year": "2019",
		"Less than $100k": 16842,
		"$100k - 200k": 58793,
		"$201k - 300k": 109228,
		"301k - 400k": 79562,
		"401k - 500k": 29827,
		"501k - 600k": 13888,
		"601k - 700k": 14298,
		"701k - 800k": 5232,
		"801k - 900k": 2657,
		"More than 901k": 6154,
	},];
	
	chart.dateFormatter.inputDateFormat = "yyyy";
	var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
	dateAxis.renderer.minGridDistance = 60;
	dateAxis.startLocation = 0.5;
	dateAxis.endLocation = 0.5;
	dateAxis.renderer.grid.template.location = 0.5;
	// dateAxis.renderer.grid.template.disabled = true;
	dateAxis.renderer.ticks.template.disabled = true;
	dateAxis.renderer.labels.template.horizontalCenter = "left";
	// dateAxis.tooltip.disabled = true;
	dateAxis.baseInterval = {
	  timeUnit: "year",
	  count: 1
	}
	
	var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	valueAxis.tooltip.disabled = true;
	valueAxis.calculateTotals = true;
	valueAxis.min = 0;
	valueAxis.max = 100;
	valueAxis.strictMinMax = true;
	
	var paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	paretoValueAxis.disabled = true;
	
	function createSeries(field, name){
		var series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.dateX = "year";
		series.name = name;
		series.dataFields.valueY = field;
		series.dataFields.valueYShow = 'totalPercent';
		series.tooltipText = "[bold]{name}[/][font-size:14px]{categoryX}: {valueY} ({valueY.totalPercent.formatNumber('#.')}%)";
		series.tooltip.background.fill = am4core.color("#FFF");
		series.tooltip.getStrokeFromObject = true;
		series.tooltip.background.strokeWidth = 3;
		series.tooltip.getFillFromObject = true;
		// series.tooltip.label.maxWidth = 100;
		series.tooltip.label.textAlign = "middle";
		series.tooltip.textAlign = "middle";
		series.fillOpacity = 0.6;
		series.strokeWidth = 2;
		series.stacked = true;
	}
	
	// var paretoSeries = chart.series.push(new am4charts.LineSeries())
	// paretoSeries.dataFields.valueY = "Median home value";
	// paretoSeries.dataFields.dateX = "year";
	// paretoSeries.yAxis = paretoValueAxis;
	// paretoSeries.tooltipText = "Median home value: ${valueY.formatNumber('###,###')}";
	// paretoSeries.strokeWidth = 0;
	
	chart.cursor = new am4charts.XYCursor();
	chart.cursor.xAxis = dateAxis;
	// chart.tooltip.label.maxWidth = 100;
	// chart.tooltip.label.wrap = true;
	
	// Add a legend
	chart.legend = new am4charts.Legend();
	chart.legend.position = "bottom";
	let title = chart.titles.create();
	title.text = 'Inflation adjusted Spokane homes value, 2008 - 2019';
	title.fontWeight = 800;
	title.marginBottom = 30;
	title.align = "left";
	
	
	createSeries("Less than $100k","Less than $100k")
	createSeries("$100k - 200k","$100k - 200k")
	createSeries("$201k - 300k","$201k - 300k")
	createSeries("301k - 400k","301k - 400k")
	createSeries("401k - 500k","401k - 500k")
	createSeries("501k - 600k","501k - 600k")
	createSeries("601k - 700k","601k - 700k")
	createSeries("701k - 800k","701k - 800k")
	createSeries("801k - 900k","801k - 900k")
	createSeries("More than 901k","More than 901k")
	
}