window.chartColors = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(201, 203, 207)'
};


var color_dict = [
	{
		"bg": "rgba(159, 4, 8, 0.7)",
		"bc": "rgba(159, 4, 8, 1)",
}, {
		"bg": "rgba(23, 80, 175, 0.7)",
		"bc": "rgba(23, 80, 175, 1)",
}, {
		"bg": "rgba(25, 145, 76, 0.7)",
		"bc": "rgba(25, 145, 76, 1)",
}, {
		"bg": "rgba(246, 193, 6, 0.7)",
		"bc": "rgba(246, 193, 6, 1)",
}, {
		"bg": "rgba(203, 106, 27, 0.7)",
		"bc": "rgba(203, 106, 27, 1)",
}, {
		"bg": "rgba(152, 78, 163, 0.7)",
		"bc": "rgba(152, 78, 163, 1)",
}, {
		"bg": "rgba(153, 153, 153, 0.7)",
		"bc": "rgba(153, 153, 153, 1)",
}, {
		"bg": "rgba(153, 77, 67, 0.7)",
		"bc": "rgba(153, 77, 67, 1)",
}, {
		"bg": "rgba(176, 103, 138, 0.7)",
		"bc": "rgba(176, 103, 138, 1)",
}, {
		"bg": "rgba(135, 152, 202, 0.7)",
		"bc": "rgba(135, 152, 202, 1)",
}, {
		"bg": "rgba(36, 200, 210, 0.7)",
		"bc": "rgba(36, 200, 210, 1)",
}, {
		"bg": "rgba(106, 235, 159, 0.7)",
		"bc": "rgba(106, 235, 159, 1)",
}, {
		"bg": "rgba(240, 249, 101, 0.7)",
		"bc": "rgba(240, 249, 101, 1)",
}, {
		"bg": "rgba(255, 99, 97, 0.7)",
		"bc": "rgba(255, 99, 97, 1)",
}, {
		"bg": "rgba(255, 159, 64, 0.7)",
		"bc": "rgba(255, 159, 64, 1)",
}, {
		"bg": "rgba(0, 63, 92, 0.7)",
		"bc": "rgba(0, 63, 92, 1)",
}, {
		"bg": "rgba(188, 80, 144, 0.7)",
		"bc": "rgba(188, 80, 144, 1)",
}, {
		"bg": "rgba(237, 177, 255, 0.7)",
		"bc": "rgba(237, 177, 255, 1)",
}, {
		"bg": "rgba(47, 221, 206, 0.7)",
		"bc": "rgba(47, 221, 206, 1)",
}, {
		"bg": "rgba(44, 245, 43, 0.7)",
		"bc": "rgba(44, 245, 43, 1)",
}]
//

var titleCase = function (str) {
	str = str.toLowerCase().split(' ');
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].toLowerCase().split('-');
		if (str[i].length > 1) {
			for (var x = 0; x < str[i].length; x++) {
				str[i][x] = str[i][x].charAt(0).toUpperCase() + str[i][x].slice(1);
			}
			str[i] = str[i].join('-');
		} else {
			str[i] = str[i].join('');
		}
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
};

var numberWithCommas = function (int) {
	return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

var getSortedKeys = function (obj) {
	var keys = keys = Object.keys(obj);
	return keys.sort(function (a, b) { return obj[b] - obj[a] });
}

var toID = function (str) {
	return str.replace(/([^A-Za-z0-9[\]{}_.:-])\s?/g, "").replace(/\./g, "").toLowerCase();
};

var toTitle = function (str) {
	return str.replace(/_/g, " ")
};

function createChart(type, data, variables) {
	am4core.useTheme(am4themes_animated);
	am4core.useTheme(am4themes_nextLI);

	let chart = am4core.create(variables['div'], am4charts.XYChart);
	let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	
	// check vars and/or set default values
	let step = (variables['step']) ? variables['step'] : 2;
	let min = (variables['min']) ? variables['min'] : valueAxis.min;
	let max = (variables['max']) ? variables['max'] : valueAxis.max;
	let strictMinMax = (variables['strictMinMax']) ? variables['strictMinMax'] : false;
	let format = (variables['format']) ? variables['format'] : '#';
	let prefix = (variables['prefix']) ? "'" + variables['prefix'] + "'" : '';
	let suffix = (variables['suffix']) ? "'" + variables['suffix'] + "'" : '';
	let toggle_bullet = (variables['bullet']) ? variables['bullet']: false;
	let stacked_totals = (variables['calculateTotals']) ? variables['calculateTotals'] : false;
	let height = (variables['height']) ? variables['height'] : 500;
	
	if (variables['microchart']) {
		am4core.useTheme(am4themes_microchart);
	}

	chart.data = data;
	chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
	chart.colors.step = step;
	chart.numberFormatter.numberFormat = prefix + format + suffix;

	categoryAxis.dataFields.category = variables['category'];
	categoryAxis.renderer.grid.template.location = 0;

	valueAxis.min = min;
	valueAxis.max = max;
	valueAxis.strictMinMax = strictMinMax;
	
	if (variables['title']) {
		let title = chart.titles.create();
		title.text = variables['title'];
		title.fontWeight = 800;
		title.marginBottom = 30;
		title.align = "left";
	}

	variables['field'].forEach(function(value, i){
		if (type == 'line') {
			createLineSeries(value, chart, variables['category'], valueAxis);
		} else if (type == 'stacked') {
			// need this for series value later
			valueAxis.calculateTotals = stacked_totals;
			createStackedSeries(value, chart, variables['category'], toggle_bullet, stacked_totals);
		} else if (type == 'pareto') {
			let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
			chart.cursor = new am4charts.XYCursor();
			chart.cursor.lineY.disabled = true;
			chart.cursor.fullWidthLineX = true;
			chart.cursor.lineX.strokeWidth = 0;
			chart.cursor.lineX.fill = am4core.color("#8F3985");
			chart.cursor.lineX.fillOpacity = 0.1;
			valueAxis.cursorTooltipEnabled = false;
			valueAxis2.cursorTooltipEnabled = false;
			chart.cursor.xAxis = categoryAxis;
			createParetoDiagram(value, i, chart, variables['category'], valueAxis, valueAxis2);
		} else if (type == 'column') {
			let fill = true;
			if (variables['field'].length != 1) {
				fill = false;
			}
			createColumnSeries(value, chart, variables['category'], fill);
		}
	});

	if (variables['legend']) {
		chart.legend = new am4charts.Legend();
	}

	if (variables['export']) {
		chart.exporting.menu = new am4core.ExportMenu();
		chart.exporting.menu.align = "right";
		chart.exporting.menu.verticalAlign = "top";
		
	}
	// chart.scrollbarX = new am4core.Scrollbar();
	$('#' + variables['div']).height(height);
	$('#' + variables['div']).width('100%');
}

function createColumnSeries(field, chart, category, fill) {
	let series = chart.series.push(new am4charts.ColumnSeries());
	
	field = checkField(field);

	series.name = field[1];
	series.dataFields.valueY = field[0];
	series.dataFields.categoryX = category;
	series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
	series.columns.template.fillOpacity = 0.8;
	
	if (fill) {
		series.columns.template.adapter.add("fill", function (fill, target) {
			return chart.colors.getIndex(target.dataItem.index);
		});
	}
	
	let columnTemplate = series.columns.template;
}

function createStackedSeries(field, chart, category, toggle_bullet, stacked_totals) {
	// Set up series
	let series = chart.series.push(new am4charts.ColumnSeries());
	field = checkField(field);

	series.name = field[1];
	series.dataFields.valueY = field[0];
	series.dataFields.categoryX = category;
	series.sequencedInterpolation = true;
	
	// Make it stacked
	series.stacked = true;
	
	// Configure columns
	series.columns.template.width = am4core.percent(60);
	
	// Add label
	if (toggle_bullet) {
		let bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.locationY = 0.5;
		if (stacked_totals) {
			bullet.label.text = "{valueY.totalPercent.formatNumber('#.##')}%";
		} else {
			bullet.label.text = "{valueY}";
		}
	}

	if (stacked_totals) {
		series.dataFields.valueYShow = "totalPercent";
		series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY.totalPercent.formatNumber('#.##')}%";
	} else {
		series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
	}

	return series;
}

function createLineSeries(field, chart, category, valueAxis) {
	let series = chart.series.push(new am4charts.LineSeries());
	let bullet = series.bullets.push(new am4charts.Bullet());
	let circle = bullet.createChild(am4core.Circle);
	
	field = checkField(field);

	series.name = field[1];
	series.dataFields.valueY = field[0];
	series.dataFields.categoryX = category;
	series.strokeWidth = 3;
	series.propertyFields.strokeDasharray = "lineDash";
	series.tooltip.label.textAlign = "middle";
	series.tensionX = 0.77;
	
	bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]";
	
	circle.radius = 3;
	circle.fill = am4core.color("#fff");
	circle.strokeWidth = 2;

	return series;
}

function createParetoDiagram(field, count, chart, category, valueAxis, valueAxis2) {
	let series;
	field = checkField(field);
	if (count > 0) {
		series = chart.series.push(new am4charts.LineSeries());
		valueAxis2.title.text = field[0];
		valueAxis2.renderer.opposite = true;
		valueAxis2.renderer.grid.template.strokeOpacity = 0;
		series.strokeWidth = 3;
		series.yAxis = valueAxis2;
	} else {
		series = chart.series.push(new am4charts.ColumnSeries());
		valueAxis.title.text = field[0];
	}
	series.dataFields.valueY = field[1];
	series.dataFields.categoryX = category;
	series.name = field[0];
	series.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]";
}

function checkField(field) {
	field = (typeof (field) === 'object') ? field : [field];
	let field_name = (field[1]) ? field[1] : field[0];
	return [field[0], field_name];
}


am4themes_nextLI = (target) => {
	if (target instanceof am4core.ColorSet) {
		target.list = [
      am4core.color("#176BA4"),
      am4core.color("#F7CA00"),
      am4core.color("#F95609"),
      am4core.color("#79ED2D"),
      am4core.color("#D4A615"),
      am4core.color("#A84111"),
      am4core.color("#8F948D"),
      am4core.color("#AD968E"),
	  am4core.color("#FC388D"),
    ];
	}

	if (target instanceof am4core.InterfaceColorSet) {
		target.setFor("primaryButton", am4core.color("#1F9BD4"));
		target.setFor("primaryButtonHover", am4core.color("#1F9BD4").lighten(-0.2));
		target.setFor("primaryButtonDown", am4core.color("#1F9BD4").lighten(-0.2));
		target.setFor("primaryButtonActive", am4core.color("#176BA4").lighten(-0.2));
		target.setFor("primaryButtonText", am4core.color("#FFFFFF"));
		target.setFor("primaryButtonStroke", am4core.color("#176BA4"));
	}
}

am4themes_reverseMaterial = (target) => {
	arr = [
		am4core.color("#F44336"),
		am4core.color("#E91E63"),
		am4core.color("#9C27B0"),
		am4core.color("#673AB7"),
		am4core.color("#3F51B5"),
		am4core.color("#2196F3"),
		am4core.color("#03A9F4"),
		am4core.color("#00BCD4"),
		am4core.color("#009688"),
		am4core.color("#4CAF50"),
		// am4core.color("#8BC34A"),
        // am4core.color("#CDDC39"),
        // am4core.color("#FFEB3B"),
        // am4core.color("#FFC107"),
        // am4core.color("#FF9800"),
        // am4core.color("#FF5722"),
        // am4core.color("#795548"),
        // am4core.color("#9E9E9E"),
        // am4core.color("#607D8B")
	  ]
	if (target instanceof am4core.ColorSet) {
	  target.list = arr.reverse();
	}
}

module.exports = { toTitle, toID, numberWithCommas, titleCase, getSortedKeys, am4themes_reverseMaterial, am4themes_nextLI, createChart};